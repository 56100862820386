import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { HomePage, MobilePage } from "@/views/index";

const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: isMobile ? MobilePage : HomePage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
