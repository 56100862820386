<template>
  <div class="about-us-container">
    <div class="sologen-text">
      <div class="slogen-text-cn">用我们的专业服务</div>
      <div class="slogen-text-cn">为您和家人的健康保驾护航</div>
      <div class="divider"></div>
      <div class="slogen-text-en">
        WITH OUR PROFESSIONAL SERVICES, WE ARE HERE TO ENSURE
      </div>
      <div class="slogen-text-en">
        THE HEALTH AND WELL-BEING OF YOU AND YOUR FAMILY
      </div>
    </div>

    <div class="introduce-us">
      <div class="title-text">关于我们</div>
      <div class="title-text-en">ABOUT US</div>
      <div class="accent-container">
        <div class="sub-title-text">公司介绍</div>
        <div class="sub-item-text">
          洺翀健康是一家创新型健康管理服务供应商。
        </div>
        <div class="sub-item-text">
          公司成立于2017年，总部位于上海。在医疗及健康管理市场有着强大的创新和整合能力。
        </div>
        <div class="sub-item-text">
          我们致力于整合国内外优质医疗资源，为中国消费者提供更高品质的健康管理综合解决方案。
        </div>
      </div>
      <div class="accent-container">
        <div class="sub-title-text">经营理念</div>
        <div class="sub-item-text">
          全生命周期健康管理理念，为员工及其家人提供一站式健康管理解决方案。
        </div>
        <div class="sub-item-text">
          整合优质医疗资源，为消费市场提供更优质灵活的整体健康管理解决方案。
        </div>
        <div class="sub-item-text">
          科技赋能-智能穿戴设备在健康管理行业的应用实践者。
        </div>
      </div>
    </div>
    <img
      class="decoration-img"
      src="@/assets/webp/decoration/decoration-top.webp"
    />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";

.about-us-container {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-top: 70px;
  .sologen-text {
    .slogen-text-cn {
      color: $primary-text-color;
      font-size: 44px;
      font-weight: 500;
      text-align: left;
    }
    .divider {
      height: 3px;
      background-color: $primary-text-color;
      width: 344px;
      margin: 20px 0;
    }
    .slogen-text-en {
      font-size: 20px;
      text-align: left;
      color: $primary-text-color;
      opacity: 0.5;
    }
  }
  .introduce-us {
    width: 664px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 8px rgba(65, 196, 171, 0.07);
    border-radius: 36px;
    opacity: 1;
    text-align: left;
    padding: 28px 40px 44px 40px;
    margin-top: 44px;

    .title-text {
      font-size: 44px;
      font-weight: 400;
      color: #383838;
      margin-left: 15px;
    }
    .title-text-en {
      margin-left: 18px;
      font-size: 20px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #7cd6c5;
      line-height: 40px;
    }
    .accent-container {
      background: #eef7ff;
      border-radius: 36px;
      opacity: 1;
      padding: 30px 34px 44px 54px;
      margin-top: 20px;

      .sub-title-text {
        font-size: 24px;
        font-weight: 500;
        color: $accent-text-color;
        line-height: 58px;
        position: relative;
        padding-left: 13px;
      }

      .sub-title-text::before {
        content: "";
        width: 3px;
        height: 18px;
        background-color: $accent-text-color;
        position: absolute;
        left: 0;
        top: 18px;
      }
      .sub-item-text {
        font-size: 20px;
        font-weight: 400;
        color: #728e87;
        line-height: 30px;
        margin-top: 16px;
        padding-left: 16px;
        position: relative;
      }
      .sub-item-text::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $accent-text-color;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    .accent-container:last-child {
      margin-top: 24px;
    }
  }
  .decoration-img {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 360px;
    height: 360px;
  }
}
</style>
