<template>
  <router-view />
</template>

<script setup lang="ts"></script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}
</style>
