import { Swiper as SwiperClass } from "swiper/types/index";
import { ref, Ref } from "vue";

export interface UseSwiperHookResponse {
  onSwiper: (swiper: SwiperClass) => void;
  onSlideChange: (v: { activeIndex: number }) => void;
  onTabBarActiveIndexChanged: (v: number) => void;
  onTabBarMouseEnter: (v: number) => void;
  onTabBarMouseLeave: (v: number) => void;
}

export const useSwiperHook = (obj: {
  currentSwiperActiveIndex: Ref<number>;
  swiperRef: Ref<SwiperClass | null>;
}): UseSwiperHookResponse => {
  const resumeAutoplayTimer = ref<number | null>(null);
  const mouseHoverIndex = ref<number | null>(0);

  const onSwiper = (swiper: SwiperClass): void => {
    obj.swiperRef.value = swiper;
  };

  const onSlideChange = (swiper: { activeIndex: number }): void => {
    obj.currentSwiperActiveIndex.value = swiper.activeIndex;
  };
  const onTabBarActiveIndexChanged = (activeIndex: number): void => {
    obj.currentSwiperActiveIndex.value = activeIndex;
    obj.swiperRef.value?.slideTo(activeIndex);
    pauseAutoplay();
  };

  const onTabBarMouseLeave = (): void => {
    mouseHoverIndex.value = null;
    console.log("鼠标离开");
    resumeAutoplay();
  };

  const onTabBarMouseEnter = (index: number): void => {
    mouseHoverIndex.value = index;
    console.log(`鼠标进入 ${index}`);
    pauseAutoplay();
  };

  const pauseAutoplay = () => {
    const isRunning = obj.swiperRef.value?.autoplay.running ?? false;
    if (isRunning) {
      obj.swiperRef.value?.autoplay.stop();
      if (resumeAutoplayTimer.value != null) {
        clearTimeout(resumeAutoplayTimer.value);
      }
      resumeAutoplayTimer.value = setTimeout(resumeAutoplay, 5000);
    } else {
      console.log("定时播放已暂停");
    }
  };

  const resumeAutoplay = () => {
    if (mouseHoverIndex.value === null) {
      console.log("开始播放---");
      obj.swiperRef.value?.autoplay.start();
    }

    if (resumeAutoplayTimer.value != null) {
      clearTimeout(resumeAutoplayTimer.value);
    }
    resumeAutoplayTimer.value = null;
    console.log(`mouseHoverIndex -, ${mouseHoverIndex.value}`);
    console.log(
      `obj.currentSwiperActiveIndex.value - ${obj.currentSwiperActiveIndex.value}`
    );
    if (obj.currentSwiperActiveIndex.value == (mouseHoverIndex.value || -1)) {
      pauseAutoplay();
    }
  };

  return {
    onSwiper,
    onSlideChange,
    onTabBarActiveIndexChanged,
    onTabBarMouseLeave,
    onTabBarMouseEnter,
  };
};
