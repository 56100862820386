import { createI18n } from "vue-i18n";
import zhCN from "@/locales/zh-CN.json";

export default createI18n({
  locale: "zh-CN",
  legacy: false,
  messages: {
    "zh-CN": zhCN,
  },
});
