export interface UseStaticJsonDataResponse {
  introduce: Array<string>;
  companyDream: Array<string>;
  productList: Array<{ name: string; items: Array<string>; img: any }>;
  smartWearServiceImgList: Array<any>;
  smartIndexImgList: Array<any>;
}

export const useStaticJsonData = (): UseStaticJsonDataResponse => {
  const introduce = [
    "洺翀健康是一家创新型健康管理服务供应商。",
    "公司成立于2017年，总部位于上海。在医疗及健康管理市场有着强大的创新和整合能力。",
    "我们致力于整合国内外优质医疗资源，为中国消费者提供更高品质的健康管理综合解决方案。",
  ];

  const companyDream = [
    "全生命周期健康管理理念，为员工及其家人提供一站式健康管理解决方案。",
    "整合优质医疗资源，为消费市场提供更优质灵活的整体健康管理解决方案。",
    "科技赋能-智能穿戴设备在健康管理行业的应用实践者。",
  ];

  const productList = [
    {
      name: "陪诊服务",
      items: [
        "当地就医陪诊",
        "异地就医陪诊（特色服务）",
        "私人订制就医陪诊",
        "代问诊 报告解读/开药",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/1.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-01.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-01.webp"),
    },
    {
      name: "门诊及住院绿通",
      items: [
        "就医协助服务: 洺翀健康为企业客户和个人客户特别提供的三甲医院就医绿色通道服务",
        "专业诊前医疗咨询",
        "快速对接医疗资源",
        "全程轻松就医协助",
        "长期健康管理服务",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/2.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-02.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-02.webp"),
    },
    {
      name: "院内和出院后康复护理",
      items: [
        "院内照护：由持证上岗的护理员为会员提供住院期间每天24小时的一对一照料，陪同并辅助会员接受各类检查等个性化照护",
        "康护到家：由职业医护师上门提供居家环境下的临床护理及专料护理服务",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/3.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-03.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-03.webp"),
    },
    {
      name: "住院/手术绿通",
      items: [
        "住院安排: 大病策划 -> 大病确诊 -> 快速住院安排 -> 医疗事务处理",
        "手术安排: 手术策划 -> 快速手术安排 -> 术中全程陪同 -> 医疗事务处理",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/4.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-04.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-04.webp"),
    },
    {
      name: "国内外二次诊疗意见",
      items: [
        "推荐合适的专家 -> 病例资料管理及更新",
        "制定最合理的方案 -> 国际国内优质医疗资源对接，安排精准治疗",
        "帮助会员在洺翀全球医疗资源中适配该疾病领域权威专家",
        "通过线上/线下等方式为会员提供病理咨询、手术及用药调整建议",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/5.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-05.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-05.webp"),
    },
    {
      name: "在线问诊配药",
      items: [
        "数干名医生全天候全国范围提供全科视频诊疗服务，无需挂号",
        "为客户提供定制化的在线寻医问药服务，改善就医体验，解决日常轻症就医需求",
      ],
      img: require("@/assets/mobile/webp/banner/health_manger_service/6.webp"),
      backgroundImg: require("@/assets/webp/service-card-background/service-06.webp"),
      frontBackgroundImg: require("@/assets/webp/service-sumary-background-06.webp"),
    },
  ];

  const smartWearServiceImgList = [
    require("@/assets/mobile/webp/banner/smart_wear_service/1.webp"),
    require("@/assets/mobile/webp/banner/smart_wear_service/2.webp"),
    require("@/assets/mobile/webp/banner/smart_wear_service/3.webp"),
    require("@/assets/mobile/webp/banner/smart_wear_service/4.webp"),
    require("@/assets/mobile/webp/banner/smart_wear_service/5.webp"),
    require("@/assets/mobile/webp/banner/smart_wear_service/6.webp"),
  ];

  const smartIndexImgList = [
    require("@/assets/mobile/webp/banner/smart_device_index/1.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/2.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/3.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/4.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/5.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/6.webp"),
    require("@/assets/mobile/webp/banner/smart_device_index/7.webp"),
  ];

  return {
    introduce,
    productList,
    companyDream,
    smartIndexImgList,
    smartWearServiceImgList,
  };
};
